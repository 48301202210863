import { Link } from "react-router-dom"
import { NavLinkMaker } from "../core/core";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "ホーム", nameEn: "HOME", path: "/home", blank: false, img: ""},
                        {nameJp: "出勤表", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: "/system", blank: false, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        {nameJp: "写メ日記",     nameEn: "DIARY", path: "https://www.cityheaven.net/kumamoto/A4301/A430101/paris_kumamoto/diarylist/", blank: true, img: ""},
                        // {nameJp: "ムービー",     nameEn: "MOVIE", path: "/", blank: true, img: ""},
                        {nameJp: "女子求人", nameEn: "RECRUIT", path: "https://www.girlsheaven-job.net/kumamoto/ma-195/sa-324/paris_kumamoto/", blank: true, img: ""},
                        {nameJp: "男子求人", nameEn: "RECRUIT-MEN", path: "https://mensheaven.jp/10/paris_kumamoto/", blank: true, img: ""},

    ];
        // アニメーション関数移植
        // useeffect内にdom操作系を記述すると、Reactの外側でdomを操作可能
        // useeffect内の処理は、ページがレンダリングされた後に実行される (≒domのエレメント取得可能)
        // 備考 : レンダリング後に処理されるため、addEventlitsnerは削除しました. ほかdom要素の取得方法を
        const scrollAnimeHandler=()=>{

            // console.log("working.")
            // const test =document.getElementsByClassName("_scroll-anim");
            // console.log(test)

            const scrollAnimElements = document.querySelectorAll("div._scroll-anim");
            // アニメーションの基準点を設定
            let animStart = window.innerHeight * 0.75;            
            if ( window.innerHeight > 400 ) {
                animStart = window.innerHeight - 100;
            }

            // 共通の処理を定義
            const handleScrollAnimation=(element)=>{
                // console.log("handler func triggerd.")
                // 要素の位置を取得
                const rect = element.getBoundingClientRect();
                // 要素が画面の上から75%の位置に来た場合にクラス "__animate__" を付与
                if (rect.top <= animStart) {
                    element.classList.add("__animate__");
                }
            }

            // スクロール時の処理を設定
            window.addEventListener("scroll", function() {

                // console.log("event-litsner triggered.")
                // console.log(scrollAnimElements)
                // クラス "_scroll-anim" の各要素に対して共通処理を実行
                scrollAnimElements.forEach(handleScrollAnimation);
            });

            // 初期化時にも共通処理を実行
            // scrollAnimElements.forEach(handleScrollAnimation);
    

        }    

        scrollAnimeHandler()

    return(
        <div id="pc-header" className="pc _scroll-anim _fadeinup">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        return(
                            <li key={el.nameEn}>
                                <NavLinkMaker data={el}>
                                    {el.nameJp}<span>{el.nameEn}</span>
                                </NavLinkMaker>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;