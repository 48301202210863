import { useEffect, useState } from "react";
import axios from "axios";
import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import {h2Unlinked, h2Linked, castCard, diaryArticle} from"./core/core";
import { apiRoot, hvnApi, shopInfo, areaName, diaryApiRoot, hvnUriRoot, slideArr, apiShopCode } from "./core/defines";

import PcNav from "./components/pcNav";
import SideNav from "./components/sideNav";

import { scrollAnimeHandlerSub } from "./helper/animation_sub"


// SPスライドのみ 出口3つあってキモい
function slideMaker(obj){
    if(obj.link.uri !== null){
        if(obj.link.blank !== false ){
            return <SwiperSlide>
                <a href={obj.link.uri} target="_blank" rel="noreferrer noopner">
                    <picture>
                        <source media="(max-width: 767px)" srcSet={obj.img.imgPathSp} />
                        <img src={obj.img.imgPathPc} alt={obj.img.alt} />
                    </picture>
                </a>
            </SwiperSlide> }
            
        else{
            return <SwiperSlide>
                <a href={obj.link.uri}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={obj.img.imgPathSp} />
                        <img src={obj.img.imgPathPc} alt={obj.img.alt} />
                    </picture>
                </a>
            </SwiperSlide> }
    }

    else{
        return <SwiperSlide>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={obj.img.imgPathSp} />
                        <img src={obj.img.imgPathPc} alt={obj.img.alt} />
                    </picture>
                </SwiperSlide> }
}

function Home(){
    const [casts,   setSchedule]  = useState([]);
    const [newface, setNewface]   = useState([]);

    useEffect(()=>{
        const fetchSchedule = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/v1/schedule?s=${apiShopCode}`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // setSchedule(data=res.data);
                console.log(res.data);
                setSchedule(res.data);
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        const fetchNewface = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/v1/casts?s=${apiShopCode}&m=newface`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // console.log(res.data);
                setNewface(res.data);
                // console.log(res.data)
            })
            .catch(err =>{
                console.log("err", err);
            });
        }

        fetchSchedule();
        fetchNewface();

},[]);

    return(
        <>

                <div id="sp-header" className="sp txt-center">
                    <p className="title">営業時間 { shopInfo.openTime }</p>
                    <div className="info">
                        <address>TEL { shopInfo.tel }</address>
                    </div>
                    <Swiper
                        modules={[Autoplay]}
                        loop={true}
                        autoplay={true}
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={10}
                        >
                            {slideArr.map((s)=>slideMaker(s))}
                    </Swiper>
                </div>


                    <p className="pc">
                        <section className="pc-swiper-wrap">
                            <div className="_scroll-anim _fadein">
                                <Swiper
                                    modules={[Autoplay]}
                                    loop={true}
                                    autoplay={true}
                                    slidesPerView={1}
                                    centeredSlides={true}
                                    spaceBetween={10}
                                    >
                                        {slideArr.map((s)=>slideMaker(s))}
                                </Swiper>
                            </div>
                        </section>
                    </p>

                    <PcNav />


            <div className="container bg-w-alpha _scroll-anim _fadeinup">
                <section id="home" className="main-colmn">
                    <article class="schedule block">
                        <div className="_scroll-anim _fadeinleft">
                            {h2Linked("本日の出勤", "出勤一覧", "/schedule")}
                        </div>
                        <div className="_scroll-anim _zoomin _delay08">
                            <ul class="wrapper-sc">
                                {casts.map((c) => 
                                    <li className="card-cast" key={c.c_id}>
                                        {castCard(c)}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </article>
                    <div className="pc">
 
                            <article id="home-hvn-diary" className="block">
                                <div className="_scroll-anim _fadeinleft _delay01">
                                    {h2Unlinked("写メ日記")}
                                </div>
                                <div className="_scroll-anim _fadeinup _delay03">
                                    <iframe src={hvnApi.Diary}></iframe>
                                    <a className="btn" href={ hvnUriRoot+ "/diarylist"}
                                        target="_blank" rel="noreferrer noopner">一覧はこちら!
                                    </a>
                                </div>                           
                            </article>
                       {/*
                            <article id="hvn-videos" className="wrappar-hvn-video block">
                                <div className="_scroll-anim _fadeinleft _delay05">
                                    {h2Unlinked("MOVIE")}
                                </div>
                                <div className="_scroll-anim _fadeinup _delay07">
                                    <iframe src={hvnApi.Video} />
                                    <a className="btn" href={ hvnUriRoot+ "/shopmovie"}
                                        target="_blank" rel="noreferrer noopner">一覧はこちら!
                                    </a>
                                </div>
                            </article>
                        */}
                    </div>
                    <article className="newface block">
                        <div className="_scroll-anim _fadeinleft _delay03">
                            {h2Linked("新人", "新人一覧", "/newface")}
                        </div>
                        <div className="_scroll-anim _fadeinup _delay05">
                            <Swiper
                                modules={[Autoplay]}
                                autoplay={true}
                                loop={true}
                                
                                slidesPerView={1.8}
                                centeredSlides={true}
                                spaceBetween={10}

                                
                                breakpoints={{
                                    567: { slidesPerView: 2.5 },  // 567px以下の場合、スライド1枚表示
                                    768: { slidesPerView: 3 }, // 768px以上の場合、スライド3枚表示
                                }}
                                >
                                {newface.map((el)=>
                                    <SwiperSlide
                                        className="card-cast txt-center"
                                        key={el.C_id}>
                                            {castCard(el)}
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </article>
                    <article className="pc">
                        <div className="_scroll-anim _fadeinleft">
                            {h2Unlinked("ACCESS")}
                        </div>
                        <div className="_scroll-anim _fadeinup">
                            <iframe className="iframe-map" src={shopInfo.gmapUri}></iframe>
                        </div>
                    </article>
                </section>
                <SideNav />
            </div>
        </>
    );
}

export default Home;