import { Link } from "react-router-dom";
import {bustArr, shopInfo, rootUri} from "./defines";

// api取得渡す可能性があるものは、ifで初回レンダリングエラー回避.
export function nl2br(words){
    const sliced = words.split(/\n/)

    return sliced.map((w)=> <>{w}<br /></>)
}
export function calender(){
    let weeks = []
    for(let i=0; i<7; i++){
        var nowD = new Date();
        nowD.setDate(nowD.getDate() + i);

        const y = nowD.getFullYear()
        const m = 1 + nowD.getMonth()
        const d = nowD.getDate()
        const w = nowD.getDay()
        weeks.push({year: y, month: m, day: d, week: w})
    }
    return weeks
}
const weekArr = ["日", "月", "火", "水", "木", "金", "土"]
// ↓備忘録 api取得先
export function makeCalenderLink(calenderArr, selectedWeek){
    const res = (dayArr) =>{
        let classN = "day"
        if(selectedWeek == dayArr.week){ classN += " selected" }
        return <Link className={classN} to={`/schedule/${dayArr.year}_${dayArr.month}_${dayArr.day}_${dayArr.week}`}>{dayArr.month}月{dayArr.day}日({ weekArr[dayArr.week] })</Link> }

    return calenderArr.map((day)=> res(day))
}


// 以下view系
export function h2Unlinked(title){
    if(title){
        return(<div className="wrapper-title"><h2 className="title">{title}</h2></div>) }
    }

export function h2Linked(title, subtitle, link, blank=false){
    if(title){
        let linkEl = (<Link to={link}>{subtitle}</Link>);
        if(blank === true){
            linkEl = (<Link to={link}
                         target="_blank" rel="noreferrer noopner">{subtitle}</Link>); }
    
        return(
            <div className="wrapper-title">
                <h2 className="title">{title}</h2>
                <nav>{ linkEl }</nav>
            </div>
            );
    }
}

export function castBody(cBody){
    if(cBody){
        return(
            <p className="body">
                <span>T{cBody.t} </span>
                <span>B{cBody.b[0] + "(" + bustArr[cBody.b[1]] + ")"} </span>
                <span>W{cBody.w} </span>
                <span>H{cBody.h}</span>
            </p>
        );
    }
}

export function castCard(cData){

    let schedule = <></>;
    if(cData.schedule){
         schedule = (<time className="sc-time">{cData.schedule.start} ~ {cData.schedule.end}</time>);}

    let imgPath= `${rootUri}/img/nophoto.jpg`
    console.log(cData.c_pic1)
    if(cData.c_pic1 == 1){
        imgPath = `${rootUri}/d/p${cData.c_id}_1.jpg` }
     
    return(
        <Link to={"/profile/" + cData.c_code}>
            <figure>
                <img src={ imgPath } />
                <figcaption className="txt-center">
                    <div className="info">
                        <h3 className="name">{cData.c_name + "("+ cData.c_age + ")"}</h3>
                        {castBody(cData.c_body)}
                    </div>
                    {schedule}
                </figcaption>
            </figure>
        </Link>
    );
}
export function diaryArticle(json){
    if(json != undefined && json !=null){
        return (
            <li key={json.url_img} className="content">
                <a href={json.url_link} target="_blank" rel="noreferrer noopner">
                    <div className="wrapper-img"><img src={json.url_img} /></div>
                    <h3>{json.title}</h3>
                </a>
            </li>
        )
    }
}


export function telBlockPc(){
    return (
        <div className="tel-block-pc pc">
            <a href="tel:0962236803"><p className="large">TEL : {shopInfo.tel}</p></a>
            <p className="small">営業時間 : {shopInfo.openTime}</p>
        </div>
    )    
}

export function NavLinkMaker(props){

    const tagTempletes = {

        vanilla: info =>{
            return <a rel="nofollow" href={info.path} target="_blank">{props.children}</a>
        },
    }

    const tagMaker=(data)=>{
        return (
            data.blank
                ? <a href={data.path} target="_blank" rel="noreferrer noopner">{props.children}</a>
                : <Link to={data.path}>{props.children}</Link>
        )
    }

    return (
        props.data.tagForced
            ? tagTempletes[props.data.requireTag](props.data)
            : tagMaker(props.data)
    )
}
