
// アニメーション関数移植
// useeffect内にdom操作系を記述すると、Reactの外側でdomを操作可能
// useeffect内の処理は、ページがレンダリングされた後に実行される (≒domのエレメント取得可能)
// 備考 : レンダリング後に処理されるため、addEventlitsnerは削除しました. ほかdom要素の取得方法を
export function scrollAnimeHandler(){

    console.log("working.")
    // const test =document.getElementsByClassName("_scroll-anim");
    // console.log(test)

    const scrollAnimElements = document.querySelectorAll("div._scroll-anim");
    // アニメーションの基準点を設定
    let animStart = window.innerHeight * 0.6;            
    if ( window.innerHeight > 600 ) {
        animStart = window.innerHeight - 240;
    }

    // 共通の処理を定義
    const handleScrollAnimation=(element)=>{
        // console.log("handler func triggerd.")
        // 要素の位置を取得
        const rect = element.getBoundingClientRect();
        // 要素が画面の上から75%の位置に来た場合にクラス "__animate__" を付与
        if (rect.top <= animStart) {
            element.classList.add("__animate__");
        }
    }

    // スクロール時の処理を設定
    window.addEventListener("scroll", function() {

        // console.log("event-litsner triggered.")
        // console.log(scrollAnimElements)
        // クラス "_scroll-anim" の各要素に対して共通処理を実行
        scrollAnimElements.forEach(handleScrollAnimation);
    });

    // 初期化時にも共通処理を実行
    if (window.location.pathname !== '/home') {
        // ここに top.js のコードを記述
        scrollAnimElements.forEach(handleScrollAnimation);
    }
}    

