import { hvnApi } from "../core/defines";
import Home from '../home';
import { scrollAnimeHandler } from "../helper/animation"

function sideNav(){

    return(
        <section id="side-nav" className="layout-box">
            {/*
                <div className="_scroll-anim _fadeinup _delay5">
                    <article className="wrappar-hvn-comments">
                        <iframe src={hvnApi.Comment} />
                    </article>
                </div>
            */}
        </section>
    );
}
export default sideNav;
