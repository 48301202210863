import {h2Unlinked} from"./core/core";
import { priceChart } from "./core/defines";
import SideNav from "./components/sideNav";

function System(){

    return(
        <div className="container bg-w-alpha _scroll-anim _fadein">
            <section className="main-colmn">
                <div className="_scroll-anim _fadeinleft _delay5">
                    {h2Unlinked("SYSTEM")}
                </div>
                <div className="_scroll-anim _zoomin _delay10">
                    <img src={priceChart} alt="料金表" />
                </div>
            </section>
            <SideNav />
        </div>
    )
}


export default System