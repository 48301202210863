
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://p-kuma.com"
export const apiRoot = "https://api.marineblue-g.com"
export const apiShopCode = "p_kuma"
export const diaryApiRoot = "https://api.marineblue-g.com/api"

// ヘブンの店舗topページ 各種連動に使う
export const hvnUriRoot = "https://www.cityheaven.net/kumamoto/A4301/A430101/paris_kumamoto"

// ヘブンブログパーツ類
export const hvnShopId = "2110054483";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "パリス熊本",
    tel:        "096-223-6803",
    openTime:   "12:00~24:00",
    zipCode:    "860-0802",
    zipAddress: "熊本県 熊本市 中央区中央街8番26号",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.7233272043945!2d130.70683031147905!3d32.79959458272161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3540f47335a66ad7%3A0x3b1c13d8b9e3675c!2z44CSODYwLTA4MDIg54aK5pys55yM54aK5pys5biC5Lit5aSu5Yy65Lit5aSu6KGX77yY4oiS77yS77yW!5e0!3m2!1sja!2sjp!4v1714530002335!5m2!1sja!2sjp",
};

export const priceChart = "/img/20240514_price.jpg"

export const areaName = "熊本"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "写メ日記", path: `${hvnUriRoot}/diarylist`, blank: true, img: "/img/ico/i-diary.png"},
/*          
        {name: "動画一覧", path: `${hvnUriRoot}/shopmovie`, blank: true, img: "/img/ico/i-video.png"},
        {name: "口コミ",   path: `${hvnUriRoot}/reviews`, blank: true, img: "/img/ico/i-review.png"},
        {name: "ネット予約", path: `${hvnUriRoot}/A6ShopReservation/`, blank: true, img: "/img/ico/i-reserve.png"},
*/
        {name: "女子求人", path: "https://www.girlsheaven-job.net/kumamoto/ma-195/sa-324/paris_kumamoto/", blank: true, img: "/img/ico/i-rec-women.png"},
        {name: "男子求人", path: "https://mensheaven.jp/10/paris_kumamoto/", blank: true, img: "/img/ico/i-rec-men.png"},

];

export const slideArr = [
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "GRAND OPEN", 
        imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=p_kuma&sp=1",
        imgPathPc: "https://api.marineblue-g.com/api/topbanner?t=p_kuma", },
    },
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "熊本最安級！", 
        imgPathSp: "/img/slides/20240408_slide2_sp.jpg",
        imgPathPc: "/img/slides/20240408_slide2_pc.jpg", },
    },
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "女性求人", 
        imgPathSp: "/img/slides/20240503_slide3_sp.jpg",
        imgPathPc: "/img/slides/20240503_slide3_pc.jpg",  },
    },
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "男性求人", 
        imgPathSp: "/img/slides/20240408_slide4_sp.jpg",
        imgPathPc: "/img/slides/20240408_slide4_pc.jpg", }, 
    },
];