import { shopInfo } from "./core/defines";
import { h2Unlinked } from "./core/core";
import SideNav from "./components/sideNav";

function Access(){

    return(
        <div className="container bg-w-alpha _scroll-anim _fadein">
            <main id="access" className="main-colmn">
                <div className="_scroll-anim _fadeinleft _delay5">
                    { h2Unlinked("ACCESS") }
                </div>
                <div className="_scroll-anim _fadeinup _delay7">
                    <div className="layout-box">
                        <h3 className="access-title">{shopInfo.name}店</h3>
                        <table className="info txt-left">
                            <tbody>
                                <tr><th>営業時間</th><td>{ shopInfo.openTime }</td></tr>
                                <tr><th>所在地</th><td>〒{ shopInfo.zipCode } { shopInfo.zipAddress }</td></tr>
                                <tr><th>TEL</th><td>{ shopInfo.tel }</td></tr>
                            </tbody>
                        </table>
                        <iframe className="gmap" src={ shopInfo.gmapUri }></iframe>
                    </div>
                </div>
            </main>
            <SideNav />
        </div>
    )
}
export default Access