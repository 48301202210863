import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import SideNav from "./components/sideNav";
import {h2Unlinked, castCard, calender, makeCalenderLink} from "./core/core";
import { apiRoot, apiShopCode } from "./core/defines"


function getSeletcedW(param){

    let selectedW = new Date().getDay()
    if(param !== undefined){ selectedW = param.split("_")[3] }
    return selectedW
}

function Schedule(){
    const {dateParam} = useParams();
    console.log(dateParam)
    const [casts,   setSchedule] = useState([]);

    useEffect(()=>{
        const fetchAllcast = (param) =>{
            // 未入力なら当日値取得(デフォ値)
            let target = `${apiRoot}/v1/schedule?s=${apiShopCode}`
            if(param !== undefined){
                target= `${apiRoot}/v1/schedule?s=${apiShopCode}&d=${param}`}

            axios({
            method: "get",
            url: target
            })
            .then((res) => {
                // console.log(res.data);
                setSchedule(res.data);
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        fetchAllcast(dateParam);
    },[dateParam]);

    return(
        <div className="container bg-w-alpha _scroll-anim _fadein">
            <article className="main-colmn">
                <div className="_scroll-anim _fadeinleft _delay5">
                    {h2Unlinked("出勤スケジュール")}
                </div>
                <div className="_scroll-anim _zoomin _delay10">
                    <nav className="block-calender" >{ makeCalenderLink(calender(), getSeletcedW(dateParam)) }</nav>
                        <ul className="wrapper-c-lists">
                            {casts.map((c)=>
                                <li key={c.c_id} className="card-cast">
                                    {castCard(c)}
                                </li>
                            )}
                        </ul>
                </div>
            </article>
            <SideNav />
        </div>
    );
}

export default Schedule;