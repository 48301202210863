import { NavLinkMaker } from "./core/core";
import { shopInfo, areaName, hvnUriRoot } from "./core/defines";
import { linkNavs } from "./core/defines";

function navFinder(nameTxt, nav){
    return nav.filter(n=>{
        return n.name == nameTxt
    })[0]
}

function Entrance() {
    return (
        <article id="entrance" className="txt-center">
            <header>{ areaName }のソープランド 【{ shopInfo.name }店】</header>
            <div className="entrance-container">
                <img src="https://api.marineblue-g.com/api/topbanner?t=p_kuma" alt="熊本のソープランド【パリス熊本店】" />
                <div className="wrappar">
                    <nav className="enter">
                        <ul>
                            <li>
                                <a className="btn to-home" href="/home">
                                    <span>ENTER</span>
                                    18歳以上のお客様ページ
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <p className="w-100">
                        <img src="/img/18kin.png" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                    </p>
                    <a className="exit" href="https://www.yahoo.co.jp" nofollow>
                        18歳未満の方は<u>コチラ</u>からご退出ください。
                    </a>
                    <div style={{marginBottom: "25px"}}>
                        <p>当グループは暴力団を含む反社会的団体<br 
                        />及びスカウト等との関わりは一切ございません。<br 
                        />■加盟協会・団体<br 
                        />・全日本特殊浴場協会連合会<br 
                        />・一般社団法人東京特殊浴場暴力団等排除推進協議会<br 
                        />・一般社団法人全国防犯健全協力会<br 
                        />・東京防犯健全協力会</p>
                    </div>	    
                </div>

                <div className="bnr-large">
                    <p>
                        <a href="https://www.cityheaven.net/kumamoto/A4301/A430101/paris_kumamoto/?of=y2" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/heavennetlink_ohp_white01.png" width="400" border="0" alt="ヘブンネット"/>
                        </a>
                    </p>
                </div>
                <div className="bnr-large">
                    <p>
                        <a href="https://www.girlsheaven-job.net/kumamoto/ma-195/sa-324/paris_kumamoto/?of=y" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/girlsheavenlink_ohp_pink01.png" width="400" border="0" alt="ガールズヘブン"/>
                        </a>
                    </p>
                </div>
                <div className="bnr-large">
                    <p>
                        <a href="https://mensheaven.jp/10/paris_kumamoto/?of=y" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/jobheavenlink_ohp01.png" width="400" border="0" alt="ジョブヘブン"/>
                        </a>
                    </p>
                </div>

                <div className="bnr-large">
                    <p>
                        <a href="https://www.cityheaven.net/kumamoto/A4301/shop-list/biz4/" target="_blank">
                            <img src="https://img.cityheaven.net/img/mutual_link/468_68_410.png" width="468" border="0" alt="熊本市のソープ｜シティヘブンネット" />
                        </a>
                    </p>
                </div>
                <div className="bnr-large">
                    <p>
                        <a href="https://www.girlsheaven-job.net/kumamoto/ma-195/" target="_blank">
                            <img src="https://img.girlsheaven-job.net/img/fppc/468_68_410.png" width="468" border="0" alt="熊本市の風俗求人｜ガールズヘブン" />
                        </a>
                    </p>
                </div>
            
                <div className="bnr-large">
                    <a rel="nofollow" href="https://kyusyu-okinawa.qzin.jp/parisukk/?v=official" target="_blank">
                        <img src="https://ad.qzin.jp/img/bnr_sp_sample_vanilla.jpg" width="400" border="0" alt="パリス熊本店で稼ぐならバニラ求人" />
                    </a>
                </div>

            </div>


        </article>
    );
}

export default Entrance;