import { Link } from "react-router-dom"

import {hvnUriRoot, shopInfo, linkNavs, areaName } from "../core/defines";
import { NavLinkMaker, telBlockPc } from "../core/core"
import { useEffect, useState } from "react";

function reserveBtn(hvnId){

    let id =""
    let linkTxt = "ネット予約"
    if(hvnId){
        id= `?girl_id=${hvnId}`
        linkTxt= "このキャストを予約"
    }

    return(
        <nav id="btn-reserve" className="btn-fix reserve">
            <a href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
        </nav>
    )
}
function telBtn(tel){

    return(
        <nav id="btn-reserve" className="btn-fix tel">
            <a className="btn-tel" href={"tel:"+ tel }>電話予約</a>
        </nav>
    )
}


function Footer({hvnId=null}){

    const [windowHeight, setWindowHeight]=useState(0)

        // アニメーション関数移植
        // useeffect内にdom操作系を記述すると、Reactの外側でdomを操作可能
        // useeffect内の処理は、ページがレンダリングされた後に実行される (≒domのエレメント取得可能)
        // 備考 : レンダリング後に処理されるため、addEventlitsnerは削除しました. ほかdom要素の取得方法を
        const scrollAnimeHandler=()=>{

            // console.log("working.")
            // const test =document.getElementsByClassName("_scroll-anim");
            // console.log(test)

            const scrollAnimElements = document.querySelectorAll("footer._scroll-anim");
            // アニメーションの基準点を設定
            let animStart = window.innerHeight * 0.9;            
            if ( window.innerHeight > 500 ) {
                animStart = window.innerHeight - 50;
            }

            // 共通の処理を定義
            const handleScrollAnimation=(element)=>{
                // console.log("handler func triggerd.")
                // 要素の位置を取得
                const rect = element.getBoundingClientRect();
                // 要素が画面の上から75%の位置に来た場合にクラス "__animate__" を付与
                if (rect.top <= animStart) {
                    element.classList.add("__animate__");
                }
            }

            // スクロール時の処理を設定
            window.addEventListener("scroll", function() {

                // console.log("event-litsner triggered.")
                // console.log(scrollAnimElements)
                // クラス "_scroll-anim" の各要素に対して共通処理を実行
                scrollAnimElements.forEach(handleScrollAnimation);
            });

            // 初期化時にも共通処理を実行
            //scrollAnimElements.forEach(handleScrollAnimation);
    

        }    

        
        useEffect(()=>{
            scrollAnimeHandler()
        }, [])
        
    return(
        <footer className="txt-center _scroll-anim _fadeinup _delay05">
            <section>
                 <a href="https://men.marineblue-g.com">
                    <img className="sp" src="/img/20231019-s-rec.jpg" alt="男女スタッフ大募集！ 店舗スタッフ未経験時給50万円～ 経験者月給80万円～" />
                </a>
                <div className="footer-sp-wrap">
                    <span>
                        <a href="/home">
                            <img className="logo" src="/img/logo-kumamoto.svg" />
                        </a>
                    </span>
                    <nav className="pc footer-box">
                        <ul className="navs">
                            {linkNavs.map((el)=>{
                                return(
                                    <li key={el.name}>
                                        <NavLinkMaker data={el}>
                                            {el.name}
                                        </NavLinkMaker>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <div>
                        {telBlockPc()}
                    </div>
                    <p className="subtitle">{ areaName }のソープランド 【{ shopInfo.name }店】</p>
                    <span className="copy">Copyright&copy;{ shopInfo.name } All Rights Reserved.</span>
                </div>
            </section>
            <div id="btn-fix-parent">
                {telBtn(shopInfo.tel)}
                {/* reserveBtn(hvnId) */}
            </div>
        </footer>
    );
}
export default Footer;